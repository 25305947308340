import Swiper from "swiper";
import SwiperCore, {Navigation, Scrollbar, Pagination, Grid, Autoplay, Controller} from 'swiper/core';

SwiperCore.use([Navigation, Scrollbar, Pagination, Grid, Autoplay, Controller]);

import 'swiper/css/bundle';

export const setSwiper = (selector, viewMob, viewTab, viewDesk,
                          betMob, betTab, betDesk, column, offset,
                          vert = false, loop= false, slideToClicked= false) => {
  let ww = window.innerWidth;
  const prev = selector.querySelector('.swiper-prev');
  const next = selector.querySelector('.swiper-next');
  const scrollbar = selector.querySelector('.swiper-scrollbar');
  const pagination = selector.querySelector('.swiper-pagination');
  const slides = [...selector.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)')];

  const swiper = new Swiper(selector, {
    slidesPerView: viewMob,
    spaceBetween: betMob,
    watchSlidesVisibility: true,
    slidesOffsetAfter: offset ? 0 : betMob,
    direction: vert ? 'vertical' : 'horizontal',
    centeredSlides: vert,
    loop: loop,
    loopedSlides: loop ? slides.length : 0,
    slideToClickedSlide: slideToClicked,
    grid: {
      rows: column ? column: 1,
      fill: column ? 'row': 'column'
    },
    navigation: {
      prevEl: prev,
      nextEl: next,
    },
    pagination: {
      el: pagination ? pagination : false,
      type: 'bullets',
      clickable: true
    },
    scrollbar: {
      el: scrollbar ? scrollbar : false,
      draggable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: viewTab,
        spaceBetween: betTab ? betTab : betMob,
        slidesOffsetAfter: offset ? 0 : betTab ? betTab : betMob,
      },
      1200: {
        slidesPerView: viewDesk,
        spaceBetween: betDesk ? betDesk : betTab,
        slidesOffsetAfter: offset ? 0 : betDesk ? betDesk : betTab,
      }
    }
  });

  window.addEventListener('resize', () => {
    if (swiper && window.innerWidth !== ww) {
      ww = window.innerWidth;
      swiper.destroy();
    }
  })
}

export const setTickerSwiper = (selector) => {
  let ww = window.innerWidth;
  const tickerSwiper = new Swiper(selector, {
    spaceBetween: 12,
    centeredSlides: true,
    speed: 6000,
    autoplay: {
      delay: 1,
    },
    loop: true,
    loopedSlides: 4,
    slidesPerView:'auto',
    allowTouchMove: false,
    disableOnInteraction: true,
    breakpoints: {
      768: {
        spaceBetween: 15,
      },
      1200: {
        spaceBetween: 20,
      }
    }
  });

  window.addEventListener('resize', () => {
    if (tickerSwiper && window.innerWidth !== ww) {
      ww = window.innerWidth;
      tickerSwiper.destroy();
    }
  });
}
