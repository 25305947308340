export const initFaq = (items) => {
  if (items.length) {
    items.forEach((faq)=> {
      const clickHandler = faq.querySelector('.faq-question .icon_open');

      clickHandler.addEventListener('click', () => {
        if (faq.classList.contains('is-active')) {
          faq.classList.remove('is-active');
        } else {
          items.forEach((f) => f.classList.remove('is-active'));
          faq.classList.add('is-active');
        }
      });
    })
  }
}
