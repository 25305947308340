export default {
  init() {
    this.DOM = {body: document.querySelector('body')};
    this.shop = this.DOM.body.querySelector('.shop__wrapper');
    this.sort = this.shop.querySelector('form.woocommerce-ordering');
    this.defaultPoint = this.shop.querySelector('#primary .site-main');
    this.filterBtn = this.shop.querySelector('.widget-title');
    this.filterWrap = this.filterBtn.closest('.widget_shop_filters');
    this.filterContent = [...this.filterWrap.querySelectorAll('.widget_layered_nav')];
    this.filters = [...this.shop.querySelectorAll('.widget.widget_layered_nav.woocommerce-widget-layered-nav')];

    // JavaScript to be fired on the shop page
    this.setEvents();
  },

  setEvents() {
    this.initSortPoint();
    this.initOpenCloseFilters();
    // this.initSeoBtn();

    let timer;
    let ww = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== ww) {
        ww = window.innerWidth;
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          this.initSortPoint();
          this.initOpenCloseFilters();
        }, 200);
      }
    });
  },

  initSortPoint() {
    if (this.shop && this.defaultPoint && this.sort) {
      if (window.innerWidth < 1200) {
        this.shop.appendChild(this.sort);
      } else {
        this.defaultPoint.appendChild(this.sort);
      }
    }
  },

  initOpenCloseFilters() {
    if (window.innerWidth < 1200) {
      this.filterBtn.addEventListener('click', () => {
        if (this.filterBtn.classList.contains('is-active')) {
          this.filterBtn.classList.remove('is-active');
          this.filterWrap.classList.remove('has-opened');
          this.filterContent.forEach(filter => filter.classList.remove('is-visible'))
        } else {
          this.filterBtn.classList.add('is-active');
          this.filterWrap.classList.add('has-opened');
          this.filterContent.forEach(filter => filter.classList.add('is-visible'))
        }
      });
    }

    this.filters.forEach(filter => {
      const filterBtn = filter.querySelector('.widget-filters__title');
      const filterContent = filter.querySelector('.woocommerce-widget-layered-nav-list');
      const chosenItems = [...filter.querySelectorAll('.chosen')];

      filterBtn.addEventListener('click', () => {
        if (filterBtn.classList.contains('is-active')) {
          filterBtn.classList.remove('is-active');
          filterContent.classList.remove('is-visible');
        } else {
          filterBtn.classList.add('is-active');
          filterContent.classList.add('is-visible');
        }
      });

      // default open filter if we have chosen items
      if (chosenItems && chosenItems.length) {
        filterBtn.classList.add('is-active');
        filterContent.classList.add('is-visible');
      }
    })
  },
}
