import 'jquery-match-height';
import {initFaq} from "../modules/initFaq";

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // setTimeout(()=>{
    //   this.setRowHeight('.offers-section__items .product-card');
    // }, 300);

    this.DOM = {body: document.querySelector('body')};

    this.faqItem = [...this.DOM.body.querySelectorAll('.faq-list__item')];
    this.initFaq();

    this.seo = document.querySelector('.seo-text');
    if (this.seo) {
      this.seoText = this.seo.querySelector('.seo-text__wrapper p');
      this.seoBtn = this.seo.querySelector('.btn-theme');

      // this.initSeoBtn();
    }
  },
  setRowHeight(selector) {
    $(selector).matchHeight();
  },
  initSeoBtn() {
    const seoTextHeight = this.seo.clientHeight;
    const lineHeight = parseInt(window.getComputedStyle(this.seoText).lineHeight);
    const lines = seoTextHeight / lineHeight;
    const btnWrap = this.seoBtn.closest('.seo-text__button');

    if (lines > 14) {
      this.seo.classList.add('has-more');
      btnWrap.classList.add('is-visible');
    } else {
      if (this.seo.classList.contains('has-more')) this.seo.classList.remove('has-more');
      if (btnWrap.classList.contains('is-visible')) btnWrap.classList.remove('is-visible');
    }

    this.seoBtn.addEventListener('click', e => {
      e.preventDefault();

      if (this.seoBtn.classList.contains('is-active')) {
        this.seoBtn.classList.remove('is-active');
        this.seo.classList.remove('has-full-text');
      } else {
        this.seoBtn.classList.add('is-active');
        this.seo.classList.add('has-full-text');
      }
    })
  },
  initFaq() {
    initFaq(this.faqItem);
  }
};
