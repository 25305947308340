import {initFaq} from '../modules/initFaq';

export default {
  init() {
    this.DOM = {body: document.querySelector('body')};
    this.faqItem = [...this.DOM.body.querySelectorAll('.faq-list__item')];

    // JavaScript to be fired on the about us page
    this.setEvents();
  },

  setEvents() {
    this.initFaq();
  },

  removeActiveFaq() {
    this.faqItem.forEach((f) => f.classList.remove('is-active'));
  },

  initFaq() {
    initFaq(this.faqItem);
  }
};
