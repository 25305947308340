import {setSwiper} from '../modules/setSwiper';
import {setFancyGallery} from '../modules/setFancybox';

export default {
  init() {
    this.DOM = {body: document.querySelector('body')};
    this.header = this.DOM.body.querySelector('.header');
    this.swiperOffers = this.DOM.body.querySelector('.swiper-slider-offers');
    this.swiperMain = this.DOM.body.querySelector('.swiper-slider-product');
    this.swiperThumb = this.DOM.body.querySelector('.swiper-slider-thumb');
    this.anchors = [...this.DOM.body.querySelectorAll('[data-scroll]')];

    // JavaScript to be fired on the shop page
    this.setEvents();
  },

  setEvents() {
    this.initSwiper();
    this.initAnchors();
    this.initFancy();

    let timer;
    let ww = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== ww) {
        ww = window.innerWidth;
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          this.initSwiper();
        }, 200);
      }
    });
  },

  initSwiper() {
    if (this.swiperOffers) setSwiper(
      this.swiperOffers, 1.15, 2.35, 4,
      20, 20, 30, null, true
    );

    if (this.swiperMain) {
      const slidesMain = [...this.swiperMain.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)')];

      if (slidesMain.length > 1 && slidesMain.length < 6) setSwiper(
        this.swiperMain, 1, 1, 1,
        0, 0, 0, null, false,
        false, false
      );

      if (slidesMain.length > 5) setSwiper(
        this.swiperMain, 1, 1, 1,
        0, 0, 0, null, false,
        false, true
      );
    }

    if (this.swiperThumb) {
      const slidesThumb = [...this.swiperThumb.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)')];

      if (slidesThumb.length > 1 && slidesThumb.length < 6) setSwiper(
        this.swiperThumb, 5, 5, 5,
        20, 19, 19, null, true,
        window.innerWidth >= 768, false, true
      );

      if (slidesThumb.length > 5) setSwiper(
        this.swiperThumb, 5, 5, 5,
        20, 19, 19, null, true,
        window.innerWidth >= 768, true, true
      );

      this.initSlidersController();
    }
  },

  initSlidersController() {
    const swiperMainSelector = this.swiperMain;
    const swiperMainElement = {swiperMainSelector};
    const swiperMain = swiperMainElement.swiperMainSelector.swiper;

    const swiperThumbSelector = this.swiperThumb;
    const swiperThumbElement = {swiperThumbSelector};
    const swiperThumb = swiperThumbElement.swiperThumbSelector.swiper;

    swiperThumb.clickable = true;
    swiperThumb.touchRatio = 0.2;

    swiperMain.controller.control = swiperThumb;
    swiperThumb.controller.control = swiperMain;

    if (this.swiperThumb.classList.contains('is-centered')) {
      const slides = [...this.swiperThumb.querySelectorAll('.swiper-slide')];
      slides.forEach((slide, inx) => {
        slide.addEventListener('click', () => {
          swiperMain.slideTo(inx);
        })
      })
    }
  },

  initFancy() {
    setFancyGallery('.main-slider__slide:not(.swiper-slide-duplicate) [data-fancybox]', 'product-fancy');
    // setFancyForm();
  },

  initAnchors() {
    if (this.anchors) {
      this.anchors.forEach(anchor => {
        const headerHeight = this.header.clientHeight;
        const section = this.DOM.body.querySelector(`#${anchor.getAttribute('data-scroll')}`);
        const top = section.getBoundingClientRect().top - headerHeight;

        anchor.addEventListener('click', () => {
          this.anchors.forEach(a => {if (a.classList.contains('is-active')) a.classList.remove('is-active')});
          anchor.classList.add('is-active');

          window.scrollTo({
            top: top,
            behavior: 'smooth'
          });
        })
      })
    }
  },
}
