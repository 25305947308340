import {setSwiper, setTickerSwiper} from '../modules/setSwiper';

export default {
  init() {
    this.DOM = {body: document.querySelector('body')};
    this.swiperBenefits = this.DOM.body.querySelector('.swiper-slider-benefits');
    this.swiperOffers = this.DOM.body.querySelector('.swiper-slider-offers');
    this.swiperGallery = this.DOM.body.querySelector('.swiper-slider-gallery');

    // JavaScript to be fired on the about us page
    this.setEvents();
  },

  setEvents() {
    this.initSwiper();

    let timer;
    let ww = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== ww) {
        ww = window.innerWidth;
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          this.initSwiper();
        }, 100);
      }
    });
  },

  initSwiper() {
    if (this.swiperBenefits && window.innerWidth < 768) setSwiper(
      this.swiperBenefits, 1.1, null, null,
      20, null, null, 4
    );

    if (this.swiperOffers) setSwiper(
      this.swiperOffers, 1.15, 2.35, 4,
      20, 20, 30, null, true
    );

    if (this.swiperGallery) {
      setTickerSwiper(this.swiperGallery)
    }
  },
};
