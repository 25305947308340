import {setSwiper} from '../modules/setSwiper';

export default {
  init() {
    this.DOM = {body: document.querySelector('body')};
    this.swiperAbout = this.DOM.body.querySelector('.swiper-slider-about');
    this.aboutContainer = this.DOM.body.querySelector('.container');
    // this.swiperAboutMargin = Number.parseInt(getComputedStyle(this.aboutContainer).paddingRight);
    this.swiperBenefits = this.DOM.body.querySelector('.swiper-slider-benefits');
    this.swiperNews = this.DOM.body.querySelector('.swiper-slider-news');

    // JavaScript to be fired on the home page
    this.setEvents();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },

  setEvents() {
    this.initSwiper();

    let timer;
    let ww = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== ww) {
        ww = window.innerWidth;
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          this.initSwiper();
        }, 200);
      }
    });
  },

  initSwiper() {
    if (this.swiperAbout) setSwiper(
      this.swiperAbout, 1, 1.11, 1.11,
      20, 30, 30
    );
    if (this.swiperBenefits && window.innerWidth < 768) setSwiper(
      this.swiperBenefits, 1.1, null, null,
      20, null, null, 4
    );
    if (this.swiperNews) setSwiper(
      this.swiperNews, 1, 1.75, 2.295,
      20, 30, 30
    );
  },

};
