import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export const setFancyGallery = (selector, cls = null) => {
  if (selector) {
    Fancybox.bind(selector, {
      infinite: false,
      placeFocusBack: false,
      mainClass: cls,
    });
  }
}

// export const setFancyForm = () => {
//   const popupFormBtns = [...document.querySelectorAll('[data-popup="form"]')];
//   const popupForm = document.querySelector('#consultation-popup');
//   const popupCloseBtn = popupForm.querySelector('.js-popup-close');
//
//   popupFormBtns.forEach(btn => {
//     if (btn && popupForm) {
//       btn.addEventListener('click', e => {
//         e.preventDefault();
//
//         Fancybox.show([
//           {
//             src: '#consultation-popup',
//             type: 'inline'
//           },
//         ])
//       })
//
//       popupCloseBtn.addEventListener('click', () => {
//         Fancybox.close();
//       })
//     }
//   })
// }
