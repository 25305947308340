import {setSwiper, setTickerSwiper} from '../modules/setSwiper';
import {initFaq} from '../modules/initFaq';

export default {
  init() {
    this.DOM = {body: document.querySelector('body')};
    this.swiperOffices = this.DOM.body.querySelector('.swiper-slider-offices');
    this.swiperGallery = this.DOM.body.querySelector('.swiper-slider-gallery');
    this.faqItem = [...this.DOM.body.querySelectorAll('.faq-list__item')];

    // JavaScript to be fired on the about us page
    this.setEvents();
  },

  setEvents() {
    this.initSwiper();
    this.initFaq();

    let timer;
    let ww = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== ww) {
        ww = window.innerWidth;
        this.removeActiveFaq();

        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          this.initSwiper();
          this.initFaq();
        }, 100);
      }
    });
  },

  initSwiper() {
    if (this.swiperOffices && window.innerWidth < 768) setSwiper(
      this.swiperOffices, 1, null, null,
      0, null, null,
    );

    if (this.swiperGallery) {
      setTickerSwiper(this.swiperGallery);
    }
  },

  removeActiveFaq() {
    this.faqItem.forEach((f) => f.classList.remove('is-active'));
  },

  initFaq() {
    if (window.innerWidth < 1200) {
      initFaq(this.faqItem);
    } else {
      this.initFaqHover();
    }
  },

  initFaqHover() {
    const defaultActive = () => {
      if (window.innerWidth >= 1200) {
        this.faqItem[0].classList.add('is-active');
      } else {
        this.removeActiveFaq();
      }
    }

    defaultActive();

    this.faqItem.forEach(faq => {
      const hovered = faq.querySelector('.name');

      hovered.addEventListener('mouseenter', () => {
        if (!faq.classList.contains('is-active')) {
          this.removeActiveFaq();
          faq.classList.add('is-active');
        }
      })
    })
  }
};
