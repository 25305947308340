// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import templateAbout from './routes/about';
import templateServices from './routes/services';
import single from './routes/article';
import error404 from './routes/error404';
import templateFaq from './routes/faq';
import archive from './routes/shop';
import templateEquipment from './routes/equipment';
import singleProduct from './routes/product';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  templateAbout,
  // Services page.
  templateServices,

  single,

  error404,

  templateFaq,

  archive,

  templateEquipment,

  singleProduct,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
