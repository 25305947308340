import {setSwiper} from '../modules/setSwiper';

export default {
  init() {
    this.DOM = {body: document.querySelector('body')};
    this.swiperNews = this.DOM.body.querySelector('.swiper-slider-news');

    // JavaScript to be fired on the about us page
    this.setEvents();
  },

  setEvents() {
    this.initSwiper();

    let timer;
    let ww = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== ww) {
        ww = window.innerWidth;
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          this.initSwiper();
        }, 100);
      }
    });
  },

  initSwiper() {
    if (this.swiperNews) setSwiper(
      this.swiperNews, 1, 2, 3,
      10, 20, 30, false, true
    );
  },
};
